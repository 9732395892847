import "lazysizes";
import "picturefill";
// var $ = require('jQuery');

//アコーディオン
$(".js-menu__item__link").each(function () {
  $(this).on("click", function () {
    $("+.system__box,+.system__box--s,+.que__box", this).slideToggle();
    $(this).toggleClass("active");
    return false;
  });
});

//ヘッダーメニュー
$(".headerSpBtn, .headerSpMenuCloseBtn, .headerSpMenuItem").on(
  "click",
  function () {
    $("html").toggleClass("scroll-prevent");
    if ($(".headerSpBtn").hasClass("active")) {
      $(".headerSpBtn").removeClass("active");
      $(".headerSpMenu").removeClass("active");
    } else {
      $(".headerSpBtn").addClass("active");
      $(".headerSpMenu").addClass("active");
    }
  }
);

$(window).scroll(function () {
  var scroll = $(window).scrollTop();

  if (scroll >= 50) {
    $(".header").addClass("active");
    $(".headerLogo").addClass("active");
    $(".headerPcMenu").addClass("active");
    $(".headerPcMenuContact").addClass("active");
    $(".headerPcTel").addClass("active");
  } else {
    $(".header").removeClass("active");
    $(".headerLogo").removeClass("active");
    $(".headerPcMenu").removeClass("active");
    $(".headerPcMenuContact").removeClass("active");
    $(".headerPcTel").removeClass("active");
  }
});

$('a[href^="#"]').click(function () {
  const windowWidth = $(window).width();
  const windowSm = 835;
  const speed = 500;
  const href = $(this).attr("href");
  const target = $(href == "#" || href == "" ? "html" : href);
  let position = 0;
  if (windowWidth > windowSm) {
    position = target.offset().top - 80;
  } else {
    position = target.offset().top - 0;
  }
  $("html, body").animate({ scrollTop: position }, speed, "swing");
  return false;
});

// プライバシー
$(function () {
  $(".wpcf7-list-item-label").replaceWith(function () {
    $(this).replaceWith(
      `<span></span><p><a class="wpcf7-list-item-label" href="${pathUrl}/privacy" target="_blank">` +
        $(this).text() +
        `<img src="${pathSrc}/img/common/external-link.svg" alt=""/></a>について同意する`
    );
  });
});
